import React, { useEffect, useState } from "react";

const TestimonialSection = () => {
  const [testimonial, setTestimonial] = useState([]);

  const [testimonialtext, setTestimonialText] = useState([]);

  useEffect(() => {
    fetch(`https://oilenergyseo17-72bb43c50eea.herokuapp.com/testimonialtext`)
      .then((res) => res.json())
      .then((info) => setTestimonialText(info));
  }, []);

  useEffect(() => {
    fetch(`https://oilenergyseo17-72bb43c50eea.herokuapp.com/testimonials`)
      .then((res) => res.json())
      .then((info) => setTestimonial(info));
  }, []);


  const divStyle = {
    backgroundImage: "url(img/testimonial/test-bg-aliments.png)",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundColor: "#fff7f5",
  };

  return (



    <>

      <main className="position-relative z-1 whiteF-bg">


        <section id="testimonial" className="client-testimonial client-testimonial__background section-space overflow-hidden parallax-element" style={{
          backgroundImage:
            "url(https://i.ibb.co/fnqQLDf/background.png)",
          paddingTop: "100px",
        }}>
          <div className="container">
            <div className="row">
              <div className="col-12">
                {
                  testimonialtext.map(a=> <div className="section-3__title-wrapper client-testimonial__content text-center mb-60 mb-sm-50 mb-xs-40">
                    <span className="section-3__subtitle justify-content-center mb-10 mb-xs-5 wow fadeIn animated" data-wow-delay=".1s"><span className="layer" data-depth="0.009">{a.testimonialText}</span> Testimonials</span>
                    <h2 className="section-3__title lg wow fadeIn animated" data-wow-delay=".3s">{a.testimonialHeading}</h2>
                  </div>)
                }
                
              </div>
            </div>
            <div className="row  align-items-center">
              <div className="col-lg-4">
                <div className="client-testimonial__slider-thubnail wow fadeIn animated" data-wow-delay=".4s">
                  <div className="client-testimonial__slider-thubnail__item">
                    <img className="img-fluid" src="https://i.ibb.co/fGrbVk0/about-silver-img.png" alt="img" />
                  </div>

                </div>
              </div>
              <div className="col-lg-1">
                <div className="client-testimonial__slider-nav wow fadeIn animated" data-wow-delay=".5s">


                </div>
              </div>
              {
                testimonial.map(e=> <div className="col-lg-7">
                  <div className="client-testimonial__slider-content wow fadeIn animated" data-wow-delay=".7s">
                    <div className="client-testimonial__slider-content__item">
                      <div className="client-testimonial__slider-content__item-header d-flex flex-wrap mb-35 mb-sm-30 mb-xs-25">
                        <div className="icon">
                          <img className="img-fluid" src="assets/imgs/client-testimonial/quote.svg" alt="image not found" />
                        </div>
                        <div className="designation d-flex justify-content-start flex-column">
                          <h4 className="rr-fw-medium">{e.personName}</h4>
                          <span>{e.personTitle}</span>
                        </div>
                      </div>
                      <div className="client-testimonial__slider-content__item-body">
                        <p>{e.desc}</p>
                      </div>
                    </div>
  
                  </div>
                </div>)
              }
              
            </div>
          </div>
        </section>

      </main>

    </>



  );
};

export default TestimonialSection;
