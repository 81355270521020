// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDvZdQ8MKpluWXXDJcFeVKiLI5SsV8vm34",
  authDomain: "oilenergyseo17clone.firebaseapp.com",
  projectId: "oilenergyseo17clone",
  storageBucket: "oilenergyseo17clone.appspot.com",
  messagingSenderId: "1048575503690",
  appId: "1:1048575503690:web:906ff6165604a9573a25b3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

export default auth;